<template>
  <div class="xinsheng-warp user-warp">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "Report",
};
</script>

<style lang="scss" scoped>
.xinsheng-container .user-warp {
  padding: 18px;
}
</style>>
